<script setup lang="ts">
/**
 * UiStack component
 *
 * Automatically layout page-level and section-level elements
 *
 * @see https://github.com/forged-com/forgd/pull/1642
 */
const props = withDefaults(defineProps<{
  dir?: 'row' | 'col'
  gap?: 'sm' | 'md' | 'lg' // could maybe refactor to section | page | whatever
  row?: boolean // shorthand for dir="row"
  page?: boolean // shorthand for gap="md"
}>(), {
  dir: 'col',
  gap: 'sm',
})

const gaps = {
  sm: `gap-5`,
  md: `gap-10`,
  lg: `gap-20`,
}

// value is NOT computed, so classes will calculate only once
const classes = [
  props.dir === 'row' || props.row
    ? 'flex-row'
    : 'flex-col',
  props.page
    ? gaps.md
    : gaps[props.gap],
]
</script>

<template>
  <div data-lo="UiStack" class="flex" :class="classes">
    <slot />
  </div>
</template>
